import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { BoxProps } from 'pws-design-system/design-system';
import StationsView from '../../stations/StationsView';
import { Station, Stations } from '../../../../models/stations';
import ActivityIndicator from '../../../ui/ActivityIndicator';
import useApi, { Api } from '../../../hooks/api/useApi';

interface UserStationsViewProps extends BoxProps {
    user: any;
}

const UserStationsView = ({ user, ...rest }: UserStationsViewProps) => {
    const [stations, setStations] = useState<Stations>();
    const [pageIndex, setPageIndex] = useState<number>(null);
    const { request: getStations, result, isLoading, paging } = useApi<Stations>((api: Api, params: any) => (
        api.routes.stations.getStations(params)
    ));

    const handlePageChange = (index: number) => {
        if (index !== pageIndex) {
            setPageIndex(index);
        }
    };

    const handleSelectStation = (station: Station) => {
        navigate(`/station/${station.id}/profile`);
    };

    const handleViewStationDashboard = (station: Station) => {
        window.open(station.dashboardUrl, '_blank');
    };

    useEffect(() => {
        getStations({
            query: `userId:${user.id}`,
            limit: paging.limit,
            page: pageIndex || 1,
        });
    }, [user]);

    useEffect(() => {
        if (result.success) {
            setStations(result.object);
        }
    }, [result]);

    return (
        <>
            {isLoading ? (
                <ActivityIndicator />
            ) : (
                <StationsView
                    stations={stations}
                    pagination={result.paging}
                    isLoading={isLoading}
                    onPageChange={handlePageChange}
                    onSelectStation={handleSelectStation}
                    onViewStationDashboard={handleViewStationDashboard}
                    includeToolbar={false}
                    isReduced
                    isUserProfile
                    {...rest}
                />
            )}
        </>
    );
};

export default UserStationsView;
