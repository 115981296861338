import React, { useState, useEffect, useRef } from 'react';
import { navigate, withPrefix } from 'gatsby';
import { Router, useLocation } from '@reach/router';
import {
    Avatar, Box, Button, Col, ExpandingMenu, Hide, Row, Text,
} from 'pws-design-system/design-system';
import { isset } from '@aerisweather/javascript-sdk/dist/utils';
import DashboardLayout from '../layouts/DashboardLayout';
import ContentPanelLayout from '../layouts/ContentPanelLayout';
import ProfileView from '../views/users/profile/ProfileView';
import UserStationsView from '../views/users/profile/UserStationsView';
import EditorToolbar from '../ui/EditorToolbar';
import Dialog from '../ui/Dialog';
import Toast, { useToast } from '../ui/Toast';
import User from '../../models/users/User';
import useDataBinding from '../hooks/useDataBinding';
import { CardHeading } from '../ui/Card';
import { useMobileQuery } from '../hooks/useMediaQuery';
import useApi, { Api, ApiError } from '../hooks/api/useApi';
import ProtectedResource from '../ProtectedResource';
import StatefulRenderer, { RenderState, useRenderState } from '../ui/StatefulRenderer';

const parsePath = (path: string): any => {
    const parts = path.replace(/^.*\/user\//, '').split('/');
    return {
        id: parts[0],
        view: parts[1],
        detail: parts[2],
    };
};

enum ViewType {
    profile = 'profile',
    stations = 'stations'
}

const UserStationsList = ({ user, ...rest }: any) => (
    <Box {...rest}>
        <CardHeading>Stations</CardHeading>
        <UserStationsView user={user} />
    </Box>
);

interface UserPageProps {
    stationId: string;
}

const UserPageContent = ({ user }: UserPageProps & any) => {
    const toast = useToast();
    const formRef = useRef<any>();
    const $confirmDelete = useDataBinding(false);

    const { request: saveUser, result: saveResult, isLoading: isSaving } = useApi<User>((api: Api, data: any) => {
        if (isset(user)) {
            return api.routes.users.updateUser(user.id, data);
        }
        return api.routes.users.addUser(data);

    });
    const { request: deleteUser, result: deleteResult, isLoading: isDeleting } = useApi<User>((api: Api) => (
        api.routes.users.deleteUser(user.id)
    ));

    const isEditing = isset(user);
    const isMoblie = useMobileQuery();

    const showToast = (success: boolean, title: string, message: string) => {
        toast({
            duration: 5000,
            isClosable: true,
            render: () => (
                <Toast variant={success ? 'success' : 'error'} title={title} message={message} icon="check-circle" />
            ),
        });
    };

    const handleTabSelect = (type: ViewType) => {
        navigate(`/user/${user.id}/${type === ViewType.profile ? '' : type}`);
    };

    const handleSave = () => {
        if (formRef.current) {
            formRef.current.submit();
        }
    };

    const handleSubmit = (data: any) => {
        saveUser(data);
    };

    const handleDeleteAction = () => {
        $confirmDelete.setValue(true);
    };

    const handleDelete = () => {
        deleteUser();
    };

    useEffect(() => {
        if (saveResult.response) {
            if (saveResult.success) {
                showToast(true, `User ${isEditing ? 'updated' : 'added'}`, `User was ${isEditing ? 'updated' : 'added'} successfully.`);
                if (isEditing === false) {
                    navigate('/users');
                }
            } else {
                showToast(false, 'User update failed', saveResult.error ? saveResult.error.message : 'The user could not be saved at this time. Please try again.');
            }
        }
    }, [saveResult]);

    useEffect(() => {
        if (deleteResult.response) {
            if (deleteResult.success) {
                navigate('/users');
                setTimeout(() => {
                    showToast(true, 'User deleted', 'User was deleted successfully.');
                }, 500);
            } else {
                showToast(false, 'User delete failed', deleteResult.error ? deleteResult.error.message : 'The user could not be deleted at this time. Please try again.');
            }
        }
    }, [deleteResult]);

    return (
        <>
            <ContentPanelLayout
                title={user ? user.name : 'Add a user'}
                category="User"
                leftElement={user && (
                    <Avatar size="lg" mt={-3} name={user.name} src="" />
                )}
                toolbarElement={(
                    <EditorToolbar
                        modelName="User"
                        isEditing={isset(user)}
                        isSaving={isSaving}
                        onSave={handleSave}
                        onDelete={handleDeleteAction}
                    />
                )}
                toolbarPlacement={isMoblie ? 'bottom' : 'top'}
                showKeyline
            >
                <Hide tablet desktop>
                    {isset(user) ? (
                        <>
                            <ExpandingMenu
                                height={46}
                                variant="light"
                                items={[{
                                    value: ViewType.profile,
                                    label: 'Profile',
                                }, {
                                    value: ViewType.stations,
                                    label: 'Stations',
                                }]}
                                onSelect={(item: any) => {
                                    handleTabSelect(item.value);
                                }}
                            />
                            <Box mt={[3, null, null, 4]}>
                                <Router basepath={withPrefix('/user/:userId')} primary={false}>
                                    <ProfileView
                                        path="/"
                                        user={user}
                                        formRef={formRef}
                                        onSubmit={handleSubmit}
                                    />
                                    <UserStationsList
                                        path="stations"
                                        user={user}
                                        mt={4}
                                    />
                                </Router>
                            </Box>
                        </>
                    ) : (
                        <Box mt={[3, null, null, 4]}>
                            <ProfileView
                                user={user}
                                formRef={formRef}
                                onSubmit={handleSubmit}
                            />
                        </Box>
                    )}
                </Hide>
                <Hide mobile>
                    <Row>
                        <Col xxl={4}>
                            <ProfileView
                                user={user}
                                formRef={formRef}
                                onSubmit={handleSubmit}
                            />
                        </Col>
                        {user && (
                            <Col xxl={8}>
                                <Box mt={4}>
                                    <CardHeading>Stations</CardHeading>
                                    <UserStationsView user={user} />
                                </Box>
                            </Col>
                        )}
                    </Row>
                </Hide>
            </ContentPanelLayout>
            <Dialog
                binding={$confirmDelete}
                title="Delete User?"
                message="Are you sure you want to delete this user? This cannot be undone!"
                buttons={[
                    <Button
                        key="btn-cancel"
                        variant="outline"
                        variantColor="dark"
                        size="sm"
                        onClick={() => $confirmDelete.setValue(false)}
                    >
                        Cancel
                    </Button>,
                    <Button
                        key="btn-confirm"
                        variant="solid"
                        variantColor="destructive"
                        size="sm"
                        onClick={() => handleDelete()}
                    >
                        Delete User
                    </Button>,
                ]}
                showActivity={isDeleting}
            />
        </>
    );
};

const UserPage = ({ pageContext, ...rest }: UserPageProps & any) => {
    const [user, setUser] = useState<User>();
    const [error, setError] = useState<ApiError>(null);
    const { renderState, setRenderState } = useRenderState();
    const location = useLocation();

    const { request: getUser, result, isLoading } = useApi<User>((api: Api, data: any) => (
        api.routes.users.getUser(data.id)
    ));

    const isEditing = pageContext.action === 'edit';

    useEffect(() => {
        if (result.success) {
            setUser(result.object);
        } else {
            setError(result.error);
        }
    }, [result]);

    useEffect(() => {
        if (user) {
            setRenderState(RenderState.Content);
        } else if (isLoading) {
            setRenderState(RenderState.Loading);
        } else if (error) {
            setRenderState(RenderState.Error);
        } else {
            setRenderState(isEditing ? RenderState.Initial : RenderState.Content);
        }
    }, [user, error, isLoading]);

    useEffect(() => {
        if (isEditing) {
            const path = parsePath(location.pathname);
            if (path.id) {
                getUser({ id: path.id });
            }
        }
    }, [location]);

    return (
        <DashboardLayout title={user ? user.name : null} onGoBack={() => navigate('/users')} {...rest}>
            <StatefulRenderer
                state={renderState}
                errorElement={<Text>{error ? error.message : null}</Text>}
            >
                <UserPageContent user={user} />
            </StatefulRenderer>
        </DashboardLayout>
    );
};

const ProtectedUserPage = (props: any) => (
    <ProtectedResource>
        <UserPage {...props} />
    </ProtectedResource>
);

export default ProtectedUserPage;
